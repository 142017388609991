/*
 * © Copyright 2014 – 2023 Open Text or one of its affiliates.
 *
 * The only warranties for products and services of Open Text and its affiliates and licensors ("Open Text") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Open Text shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

import './Header.scss';
import React, {Fragment} from 'react';
import {Dialog, Menu, createToast} from '../../ux/ux';
import {connect} from 'react-redux';
import {openCompanyPage, signOut, viewLanguagesPage, HELP_URL} from '../../actions/navigation.actions';
import {AUTHENTICATION_STATES} from '../../reducers/authentication.reducer';
import {deleteUser} from '../../actions/authentication.actions';
import {STATUS_TYPE} from '../../ux/StatusIndicator';
import LoadingIndicator from '../../ux/LoadingIndicator';
import t from '../../i18n/locale-keys';
import {autocreatedMethods} from '../../data/MethodData';

class Header extends React.PureComponent {
    state = {
        confirmDeleteDialogOpen: false,
        okDeleteDialogOpen: false,
    };

    handleLanguagesClick = () => {
        this.props.viewLanguagesPage();
    };

    handleSignOutClick = () => {
        this.props.signOut();
    };

    handleRemoveEnrolledMethodsClick = () => {
        this.setState({
            okDeleteDialogOpen: true,
        });
    };

    okConfirmDeleteDialog = () => {
        this.setState({
            confirmDeleteDialogOpen: true,
            okDeleteDialogOpen: false,
        });
    };

    cancelConfirmDeleteDialog = () => {
        this.setState({
            confirmDeleteDialogOpen: false,
            okDeleteDialogOpen: false,
        });
    };

    deleteConfirmDeleteDialog = () => {
        this.setState({
            confirmDeleteDialogOpen: false
        });

        //do deletion
        this.props.deleteUser().then(() => {
            createToast({type: STATUS_TYPE.OK, description: t.authenticatorsDeleted()});
        });
    };

    checkAutoEnrolledMethods = () => {
        let isAutoCreatedMethods;
        for (let i = 0; i < this.props.enrolledTemplates.length; i++) {
            isAutoCreatedMethods = autocreatedMethods.includes(this.props.enrolledTemplates[i].methodId);
            if (!isAutoCreatedMethods) {
                break;
            }
        }
        return isAutoCreatedMethods;
    };

    renderDeleteMeButton() {
        const isAutoCreatedMethods = this.checkAutoEnrolledMethods();

        if (!this.props.policies || !this.props.policies.deleteMeOptions.data.deleteMeEnabled || isAutoCreatedMethods) {
            return null;
        }

        return (
              <button
                  className="ias-button"
                  id="Delete_Enrollments_Button"
                  onClick={this.handleRemoveEnrolledMethodsClick}
                  type="button"
              >
                  {t.authenticatorsDelete()}
              </button>
        );
    }

    renderUserName = () => {
        let userName;
        if (this.props.authentication.tenantName === 'TOP') {
            userName = this.props.policies && this.props.policies.multitenancyOptions.data.multitenancyEnabled
                ? this.props.authentication.tenantName + '\\' + this.props.authentication.username
                : this.props.authentication.username;
        }
        else {
            userName = this.props.authentication.tenantName + '\\' + this.props.authentication.username;
        }
        return userName;
    };

    generateCustomStyles = () => {
        const appBarColor = this.props.branding.isBlendedColor && this.props.branding.backgroundColorRight ? (
            {background: 'linear-gradient(to right, ' + this.props.branding.backgroundColorLeft + ', ' +
                    this.props.branding.backgroundColorRight + ')'}) :
            {background: this.props.branding.backgroundColorLeft};
        const titleTextColor = this.props.branding.titleColor ? {color: this.props.branding.titleColor} : {};
        let companyLogo;
        //Use provided image
        if (this.props.branding.useImage && !!this.props.branding.logoLg.length) {
            companyLogo = 'data:image/png;base64,' + this.props.branding.logoLg;
        }
        //Use image but none provided so get default
        else if (this.props.branding.useImage && !this.props.branding.logoLg.length) {
            companyLogo = process.env.PUBLIC_URL + '/opentext_logo_inverse_100h.png';
        }
        else {
            companyLogo = null;
        }
        let companyTitle;
        if (this.props.branding.showTitle) {
            companyTitle = !!this.props.branding.title.length ? this.props.branding.title : t.productName();
        }
        else if (!this.props.policies) {
            companyTitle = t.productName();
        }

        return {appBarColor, titleTextColor, companyLogo, companyTitle};
    };

    openBrandingLinkURL = () => this.props.openCompanyPage(this.props.branding.linkUrl);

    isDisplayAppbar = () => {
        const AuthStatus = this.props.authentication.status;

        //Return true if policies are available meaning we've logged in and loaded.  Otherwise check if we're logging in
        return this.props.policies && this.props.policies.logoOptions ? true
            : AuthStatus === AUTHENTICATION_STATES.USERNAME_ENTRY;
    };

    render() {
        const loggedIn = (this.props.authentication.status === AUTHENTICATION_STATES.LOGGED_IN);
        const deleteMeButton = loggedIn ? this.renderDeleteMeButton() : null;
        const menuTitle = loggedIn ? this.renderUserName() : t.menu();
        const additionalMenuOptions = loggedIn ? (
            <React.Fragment>
                {deleteMeButton}
                <div className="menu-separator" />
                <button type="button" className="ias-button" id="Sign_Out_Button" onClick={this.handleSignOutClick}>
                    {t.signOut()}
                </button>
            </React.Fragment>
        ) : null;
        const customBranding = this.generateCustomStyles();
        const appBarIcon = customBranding.companyLogo ? (
            <div className="ias-avatar" id="Micro_Logo" onClick={this.openBrandingLinkURL}>
                <img
                    alt={t.companyLogo()}
                    className="micro-logo"
                    src={customBranding.companyLogo}
                />
            </div>
        ) : null;
        const appBarActions = loggedIn ? (
            <Fragment>
                <a className="ias-button ias-icon-button"
                   type="button"
                   id="Help_Link"
                   href={HELP_URL}
                   rel="noopener noreferrer"
                   target="_blank"
                >
                    <i className="ias-icon ias-icon-help_thick" title="Help" style={customBranding.titleTextColor} />
                </a>

                <Menu iasAlign="end" toggleElement={(
                        <button className="ias-button ias-menu-toggle" id="Menu_Toggle_Button" type="button">
                            <span style={customBranding.titleTextColor}>{menuTitle}</span>
                            <i className="ias-icon ias-icon-down_thick" style={customBranding.titleTextColor} />
                        </button>
                    )}
                >
                    <button className="ias-button"
                            id="Language_Button"
                            onClick={this.handleLanguagesClick}
                            type="button"
                    >
                        {t.language()}
                    </button>
                    {additionalMenuOptions}
                </Menu>
            </Fragment>
        ) : (
            <button className="ias-button ias-icon-button"
                    id="Language_Button"
                    onClick={this.handleLanguagesClick}
                    type="button"
            >
                <i className={'ias-icon ias-icon-world_thin'}
                   title={t.language()}
                   style={customBranding.titleTextColor}
                />
            </button>
        );
        return this.isDisplayAppbar() ? (
            <header>
                <div className="ias-app-bar ot-bg-color" style={customBranding.appBarColor}>
                    {appBarIcon}
                    <h3 className="ias-heading"
                        id="Product_Name"
                        style={customBranding.titleTextColor}
                        onClick={this.openBrandingLinkURL}
                    >
                        {customBranding.companyTitle}
                    </h3>
                    <span className="ias-fill" />
                    {appBarActions}
                </div>
                <Dialog
                    onCancel={this.cancelConfirmDeleteDialog}
                    onClose={this.okConfirmDeleteDialog}
                    open={this.state.okDeleteDialogOpen}
                    title={
                        <div className="ias-dialog-label">
                            {t.authenticatorsDeleteWarningTitle()}
                        </div>
                    }
                >
                    <p>{t.authenticatorsDeleteWarningOk()}</p>
                </Dialog>
                <Dialog
                    onCancel={this.cancelConfirmDeleteDialog}
                    actionButtonsWithDelete
                    onClose={this.deleteConfirmDeleteDialog}
                    open={this.state.confirmDeleteDialogOpen}
                    title={
                        <div className="ias-dialog-label">
                            {t.authenticatorsDeleteWarningTitle()}
                        </div>
                    }
                >
                    <p>{t.authenticatorsDeleteWarning()}</p>
                </Dialog>
            </header>
        ) : <LoadingIndicator message={t.loading()} />;
    }
}

const mapStateToProps = ({authentication, locale: {language}, methodsDisplay: {policies, enrolledTemplates}}) =>
    ({authentication, language, policies, branding: policies ? policies.logoOptions.data : {}, enrolledTemplates});

const mapDispatchToProps = {
    viewLanguagesPage,
    signOut,
    openCompanyPage,
    deleteUser
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
